<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px;">
                <v-container class="hidden-sm-and-down" style="margin-top: 130px; margin-bottom: 200px;">
                    <h2 class="intro-text-head hidden-sm-and-down mb-10">Beneficios Adicionales</h2>
                    <center>
                        <p class="intro-text-head-desc mt-2 hidden-sm-and-down">
                            Al crear una cuenta en Pill ATS, accedes a nuestra red de partners, quienes te ofrecen
                            servicios especializados para tu empresa<br> <span style="font-weight: 600">¡con descuentos
                                exclusivos que impulsarán tu
                                crecimiento!</span>
                        </p>
                    </center>
                   
                    <br>
                    <br>
                    <v-card v-for="(card, index) in cardsBenefitsPartner" :key="index" class="mx-auto elevation-0 mb-2 benefits-card" 
                        outlined style="border-radius: 10px; max-width: 1200px;">
                        <v-row style="margin-bottom: 0.02rem !important;">
                            <v-col style="max-width: min-content;" class="ml-8">
                                <v-avatar class="mt-5" :color="[''].includes(card.id) ? '#1D1E18' : ''"
                                    size="80" tile style="border-radius: 7px !important;">
                                    <v-img :src="card.avatar" contain style=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col style="max-width: 70%;">
                                <p class="mt-4" style="font-size: 1.1rem; font-weight: 600;">{{ card.title }} <v-btn
                                        x-small rounded color="#2c7af820" class="elevation-0 ml-5 mb-1"><v-icon x-small
                                            left color="#2c7af8">mdi-ticket-percent</v-icon><span
                                            style="color: #2c7af8;">{{ card.deal }}</span></v-btn></p>
                                <p style="font-size: small;" class="mr-3">{{ card.description }}</p>
                            </v-col>
                            <v-col style="max-width: 30%;">
                                <v-btn small rounded class="mx-2 mt-13" outlined color="black"
                                    @click="goToSpecificBenefit(card.id)">Ver Beneficio <v-icon small
                                        right>mdi-arrow-top-right</v-icon></v-btn>
                                <v-btn small rounded class="mt-13 mr-2 white--text elevation-0" color="#7579e7"
                                    @click="goToRegister()">Desbloquear <v-icon small right>mdi-lock</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <center>
                        <a href="mailto:pill.startup@gmail.com" style="text-decoration: none;"><v-btn class="ma-5"
                                rounded outlined>Quiero ser partner de Pill</v-btn></a>

                    </center>

                </v-container>
                <v-container class="hidden-md-and-up" style="margin-top: 130px; margin-bottom: 200px;">
                    <h2 class="intro-text-head-mb">Beneficios Adicionales</h2>
                    <p class="intro-text-head-desc-mb mt-8 ml-2 mr-2" style="text-align: justify;">Crea una cuenta en
                        Pill ATS y accede a servicios especializados con descuentos exclusivos para tu empresa.</p>
                    <br>
                    <br>
                    <v-card v-for="(card, index) in cardsBenefitsPartner" :key="index" class="mx-2 elevation-0 mb-4 benefits-card"
                        outlined style="border-radius: 20px;">
                        <v-row style="">
                            <v-col style="max-width: min-content;" class="ml-5">
                                <v-avatar class="mt-5" :color="[''].includes(card.id) ? '#1D1E18' : ''"
                                    size="80" tile style="border-radius: 7px !important;">
                                    <v-img :src="card.avatar" contain style=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col style="max-width: 70%;">
                                <p class="mt-5" style="font-size: 1.3rem; font-weight: 600;">{{ card.title }}</p>
                                <v-btn x-small rounded color="#2c7af820" class="elevation-0 mb-1"><v-icon x-small
                                        left color="#2c7af8">mdi-ticket-percent</v-icon><span style="color: #2c7af8;">{{
                                        card.deal }}</span></v-btn>

                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>
                                <p style="font-size: small; text-align: justify;" class="ml-5 mr-5">{{ card.description }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-col style="max-width: 50%;">
                                <v-btn small rounded class="ml-5" outlined color="black"
                                    @click="goToSpecificBenefit(card.id)">Ver Beneficio <v-icon small
                                        right>mdi-arrow-top-right</v-icon></v-btn>

                            </v-col>
                            <v-col style="max-width: 50%;">
                                <v-btn small rounded class="mr-5 white--text elevation-0" color="#7579e7"
                                    @click="goToRegister()">Desbloquear <v-icon small right>mdi-lock</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <center>
                        <a href="mailto:pill.startup@gmail.com" style="text-decoration: none;"><v-btn class="ma-5"
                                rounded outlined>Quiero ser partner de Pill</v-btn></a>
                    </center>

                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            cardsBenefitsPartner: [
                {
                    id: "chatpro",
                    title: "ChatPro AI",
                    avatar: "https://d153chakadu90d.cloudfront.net/web/chatpro_logo_v2_vertical.webp",
                    additionalLogo: "https://d153chakadu90d.cloudfront.net/web/chatpro_logo_v2_horizontal.webp",
                    description: "Crea asistentes de IA entrenados con tus propios datos, entrena modelos LLM con tus archivos PDF, Word, TXT, Website, Youtube Videos. Conecta tu Chatbot a tu website y responde a las preguntas de tus clientes en automatico.",
                    deal: "20% OFF en todos los Planes",
                    additionalImg: "https://media.licdn.com/dms/image/v2/D4E22AQHoKxM8K1LicQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1697520425593?e=1738800000&v=beta&t=k8yIS7MiOpYqWhEO6be7Oz0e0UNWpXl9JxIiTsqdT7g",
                    website: "https://chatpro.pro",
                    websiteLabel: "www.chatpro.pro",
                    additionalText: `
        <p>ChatPro AI es una plataforma de IA que te permite crear Asistentes de IA para tu website, entrena los ultimos modelos de IA con los siguientes Data Sources:</p>
        <br>
        <ul>
            <li>PDFs.</li>
            <li>Youtube Videos.</li>
            <li>Word Documents.</li>
            <li>Excel Sheets</ul>
            <li>Plain Text</ul>`,
                },
                {
                    id: "loop",
                    title: "Loop Design LLC",
                    avatar: "https://framerusercontent.com/images/a4gS1ef04qw7FQiAyt6axdD1WnQ.png?scale-down-to=512",
                    additionalLogo: "https://framerusercontent.com/images/a4gS1ef04qw7FQiAyt6axdD1WnQ.png?scale-down-to=512",
                    description: "Diseño gráfico | Diseño Web | Diseño UI | Edición de video - Una suscripción para todos tus proyectos de diseño escala y delega todas las operaciones de diseño de tu organización con un servicio de diseño confiable y sin complicaciones.",
                    deal: "15% OFF en todos los planes",
                    additionalImg: "https://framerusercontent.com/images/fJ0oS8oS9Y10ypLOL6a2xnAKQ5w.jpg",
                    website: "https://loopdesign.framer.ai/",
                    websiteLabel: "weareloopdesign.com",
                    additionalText: `
        <p>Loop Design ofrece una solución de diseño 360° sin limitaciones. Con nuestro equipo creativo a la vanguardia, entregamos proyectos con calidad excepcional, entregas rápidas y siempre enfocados en superar tus expectativas. Desde branding hasta desarrollo web, cada diseño refleja tu visión y valores, creando experiencias únicas para tus clientes. Confía en nosotros para elevar tu marca y conectarte con tu audiencia.</p>
        <br>
        <h2>Nuestros Servicios</h2>
        <br>
        <ul>
            <li><strong>Diseño de Marca:</strong> Creamos una identidad visual sólida que representa los valores de tu empresa.</li>
            <li><strong>Desarrollo Web:</strong> Desarrollamos sitios web responsivos, optimizados para conversión y experiencia de usuario.</li>
            <li><strong>UX/UI Design:</strong> Creamos interfaces digitales intuitivas y visualmente atractivas que conectan a las marcas con sus usuarios.</li>
            <li><strong>Motion Graphics & Animación:</strong> Damos vida a tus ideas con animaciones que capturan la atención y comunican tu mensaje de manera clara.</li></ul>`
                },
                {
                    id: "hostinger",
                    title: "Hostinger",
                    avatar: "https://upload.wikimedia.org/wikipedia/commons/0/03/Hostinger_Logo.png",
                    additionalLogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Hostinger_logo_black.svg/2560px-Hostinger_logo_black.svg.png",
                    description: "Desde la creación de tu primer sitio web y el registro de tu dominio, hasta la configuración de un email profesional, la publicación de tu eCommerce o la migración de un sitio web existente. Aquí comienza tu éxito en Internet.",
                    deal: "20% OFF en Planes Cloud y VPS",
                    additionalImg: "",
                    website: "https://hostinger.co?REFERRALCODE=FVZREBELRUON",
                    websiteLabel: "hostinger.com",
                    additionalText: ""
                    
                },
                {
                    id: "testimonial",
                    title: "Testimonial",
                    avatar: "https://testimonial.to/static/media/logo-dark.8447f219.svg",
                    additionalLogo: "https://testimonial.to/static/media/logo-dark.8447f219.svg",
                    description: "Obtén testimonios de tus clientes con facilidad | Recopilar testimonios es difícil, ¡lo entendemos! Así que creamos Testimonial. En minutos, puede recopilar testimonios de texto y video de sus clientes sin necesidad de un desarrollador o alojamiento de sitios web.",
                    deal: "15% OFF Primer Año",
                    additionalImg: "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/assets%2Ffeatures%2Fmore-social-proof.png?alt=media&token=83a1a3e9-449d-457d-80fb-0cfa55484700",
                    website: "https://testimonial.to/?via=pill-ats",
                    websiteLabel: "testimonial.to",
                    additionalText: ""
                    
                },
                // {
                //     id: "growbox",
                //     title: "GrowBox",
                //     avatar: "https://media.licdn.com/dms/image/v2/D4E0BAQH2utbMWUTsLw/company-logo_200_200/company-logo_200_200/0/1728939051907/growboxdigital_logo?e=2147483647&v=beta&t=ACEntyX7p46XGr0FkIFlxBrggWC0tb1fOVMc_tUY3rM",
                //     additionalLogo: "https://www.growboxdigital.com/hubfs/Logo%20Growbox%20web.png",
                //     description: "Impulsa tu crecimiento con nuestra implementación y optimización experta. Como Agencia Partner de Hubspot, desbloqueamos todo el potencial de la plataforma para llevar tus estrategias de marketing y ventas al siguiente nivel.",
                //     deal: "Special Offer",
                //     additionalImg: "",
                //     website: "https://www.growboxdigital.com/",
                //     websiteLabel: "growboxdigital.com",
                //     additionalText: ""
                // }

            ],

        }
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro landing
        },
        goToSpecificBenefit(partnerId) {
            this.$router.push("/benefits/"+partnerId) //redirecciona a chatpro landing
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: black;
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
}

.intro-text-head-desc {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

/* .benefits-card {
  transition: transform 0.3s ease-in-out;
}

.benefits-card:hover {
  transform: translateY(-25px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
} */
</style>