<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px;">
                <!--PC-->
                <v-container class="hidden-sm-and-down" style="margin-top: 130px; margin-bottom: 200px;">
                    <v-row>
                        <v-col>
                            <v-btn small class="ma-2 elevation-0" style="background-color: white;"
                                @click="goToBenefits()"><v-icon left small>mdi-chevron-left</v-icon>Volver</v-btn>
                        </v-col>
                        <v-col style="text-align: right;">
                            <a :href='filteredBenefit.website' style="text-decoration: none;" target="_blank">
                                <v-btn small class="ma-2 elevation-0 white--text" rounded
                                    color="black">{{ filteredBenefit.websiteLabel }}<v-icon right
                                        small>mdi-arrow-top-right</v-icon></v-btn>
                            </a>
                        </v-col>
                    </v-row>
                    <v-card class="elevation-2" outlined style="border-radius: 20px;">
                        <v-row class="mx-7 mt-2">
                            <!-- Logo Partner -->
                            <v-col class="d-flex justify-center align-center" style="max-width: min-content;">
                                <v-img
                                    :src="filteredBenefit.additionalLogo"
                                    style="width: 7.2rem;"></v-img>
                            </v-col>

                            <v-col class="d-flex justify-center align-center" style="max-width: min-content">
                                <p class="mt-5" style="font-size: 24px;">+</p>
                            </v-col>

                            <!-- Pill Logo -->
                            <v-col class="d-flex justify-center align-center" style="max-width: min-content;">
                                <v-img src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp"
                                    style="width: 6.5rem;"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-card-title class="ml-7" style="font-weight: 600;">{{ filteredBenefit.title }}<v-btn small rounded
                                    color="#2c7af820" class="elevation-0 ml-5 mb-1"><v-icon small left
                                        color="#2c7af8">mdi-ticket-percent</v-icon><span style="color: #2c7af8;">{{ filteredBenefit.deal }}</span></v-btn></v-card-title>
                            <v-card-text class="ml-7 mr-7" style="color: #9CA3AF; text-align: justify;">{{ filteredBenefit.description }}</v-card-text>
                        </v-row>
                        <div>
                            <center>
                                <v-img :src="filteredBenefit.additionalImg"
                                    style="width: 90%; border-radius: 25px;" class="mt-5 ml-16 mr-16"></v-img>
                            </center>
                        </div>
                        <v-row>

                            <v-card-text class="ml-7 mr-7 mt-5 mb-5" style="text-align: justify;"
                                v-html="filteredBenefit.additionalText"></v-card-text>
                        </v-row>
                        <v-card-action>
                            <v-row>
                                <v-col>

                                </v-col>
                                <v-col style="text-align: right;">
                                    <a :href='filteredBenefit.website' style="text-decoration: none;"
                                        target="_blank">
                                        <v-btn class="ma-5 white--text" color="#7579e7" rounded>Visitar Website <v-icon
                                                right>mdi-chevron-right</v-icon></v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card-action>

                    </v-card>
                </v-container>
                <v-container class="hidden-md-and-up" style="margin-top: 130px; margin-bottom: 200px;">
                    <v-row>
                        <v-col style="width: fit-content;">
                            <v-btn small class="ma-2 elevation-0" style="background-color: white;"
                                @click="goToBenefits()"><v-icon left small>mdi-chevron-left</v-icon>Volver</v-btn>
                        </v-col>
                        <v-col style="text-align: right;">
                            <a :href='filteredBenefit.website' style="text-decoration: none;" target="_blank">
                                <v-btn small class="ma-2 elevation-0 white--text" rounded
                                    color="black">{{ filteredBenefit.websiteLabel }}<v-icon right
                                        small>mdi-open-in-new</v-icon></v-btn>
                            </a>
                        </v-col>
                    </v-row>
                    <v-card class="elevation-2" outlined style="border-radius: 20px;">
                        <v-row class="ml-5 mt-2">
                            <!-- Logo Partner -->
                            <v-col class="d-flex justify-center align-center" style="max-width: min-content;">
                                <v-img
                                    :src="filteredBenefit.additionalLogo"
                                    style="width: 6rem;"></v-img>
                            </v-col>

                            <v-col class="d-flex justify-center align-center" style="max-width: min-content">
                                <p class="mt-5" style="font-size: 24px;">+</p>
                            </v-col>

                            <!-- Pill Logo -->
                            <v-col class="d-flex justify-center align-center" style="max-width: min-content;">
                                <v-img src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp"
                                    style="width: 6rem;"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-card-title class="ml-7" style="font-weight: 600">{{ filteredBenefit.title }}<v-btn small rounded color="#2c7af820" class="elevation-0 ml-5 mb-1"><v-icon small left
                                        color="#2c7af8">mdi-ticket-percent</v-icon><span style="color: #2c7af8;">{{ filteredBenefit.deal }}</span></v-btn></v-card-title>
                            <v-card-text class="ml-7 mr-7" style="color: #9CA3AF; text-align: justify;">{{ filteredBenefit.description }}</v-card-text>
                        </v-row>
                        <div>
                            <center>
                                <v-img :src="filteredBenefit.additionalImg"
                                    style="width: 90%; border-radius: 10px;" class="mt-5"></v-img>
                            </center>
                        </div>
                        <v-row>

                            <v-card-text class="ml-7 mr-7 mt-5 mb-5" style="text-align: justify;"
                                v-html="filteredBenefit.additionalText"></v-card-text>
                        </v-row>
                        <v-card-action>
                            <v-row>
                                <v-col>

                                </v-col>
                                <v-col style="text-align: right;">
                                    <a :href='filteredBenefit.website' style="text-decoration: none;"
                                        target="_blank">
                                        <v-btn class="ma-5 white--text" color="#7579e7" rounded>Visitar Website <v-icon
                                                right>mdi-chevron-right</v-icon></v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card-action>

                    </v-card>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            cardsBenefitsPartner: [
            {
                    id: "chatpro",
                    title: "ChatPro AI",
                    avatar: "https://d153chakadu90d.cloudfront.net/web/chatpro_logo_v2_vertical.webp",
                    additionalLogo: "https://d153chakadu90d.cloudfront.net/web/chatpro_logo_v2_horizontal.webp",
                    description: "Crea asistentes de IA entrenados con tus propios datos, entrena modelos LLM con tus archivos PDF, Word, TXT, Website, Youtube Videos. Conecta tu Chatbot a tu website y responde a las preguntas de tus clientes en automatico.",
                    deal: "20% OFF en todos los Planes",
                    additionalImg: "https://media.licdn.com/dms/image/v2/D4E22AQHoKxM8K1LicQ/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1697520425593?e=1738800000&v=beta&t=k8yIS7MiOpYqWhEO6be7Oz0e0UNWpXl9JxIiTsqdT7g",
                    website: "https://chatpro.pro",
                    websiteLabel: "www.chatpro.pro",
                    additionalText: `
        <p>ChatPro AI es una plataforma de IA que te permite crear Asistentes de IA para tu website, entrena los ultimos modelos de IA para atender las dudas y requerimientos de tus clientes en automatico y las 24 horas del dia, unete a usuarios de mas de 15 paises que utilizan ChatPro AI a dia de hoy. <br> Integra tu Asistente de IA con las aplicaciones que utilizas en tu dia a dia.</p>
        <br>
        <h3>Entrena Modelos de IA con Data Sources:</h3>
        <br>
        <ul class="ml-5">
            <li>PDFs.</li>
            <li>Youtube Videos.</li>
            <li>Word Documents.</li>
            <li>Excel Sheets.</li>
            <li>Plain Text.</li>
            <li>Website.</li></ul>`,
                },
                {
                    id: "loop",
                    title: "Loop Design LLC",
                    avatar: "https://framerusercontent.com/images/a4gS1ef04qw7FQiAyt6axdD1WnQ.png?scale-down-to=512",
                    additionalLogo: "https://framerusercontent.com/images/a4gS1ef04qw7FQiAyt6axdD1WnQ.png?scale-down-to=512",
                    description: "Diseño gráfico | Diseño Web | Diseño UI | Edición de video - Una suscripción para todos tus proyectos de diseño escala y delega todas las operaciones de diseño de tu organización con un servicio de diseño confiable y sin complicaciones.",
                    deal: "15% OFF en todos los planes",
                    additionalImg: "https://framerusercontent.com/images/fJ0oS8oS9Y10ypLOL6a2xnAKQ5w.jpg",
                    website: "https://loopdesign.framer.ai/",
                    websiteLabel: "weareloopdesign.com",
                    additionalText: `
        <p>Loop Design ofrece una solución de diseño 360° sin limitaciones. Con nuestro equipo creativo a la vanguardia, entregamos proyectos con calidad excepcional, entregas rápidas y siempre enfocados en superar tus expectativas. Desde branding hasta desarrollo web, cada diseño refleja tu visión y valores, creando experiencias únicas para tus clientes. Confía en nosotros para elevar tu marca y conectarte con tu audiencia.</p>
        <br>
        <h2>Nuestros Servicios</h2>
        <br>
        <ul>
            <li><strong>Diseño de Marca:</strong> Creamos una identidad visual sólida que representa los valores de tu empresa.</li>
            <li><strong>Desarrollo Web:</strong> Desarrollamos sitios web responsivos, optimizados para conversión y experiencia de usuario.</li>
            <li><strong>UX/UI Design:</strong> Creamos interfaces digitales intuitivas y visualmente atractivas que conectan a las marcas con sus usuarios.</li>
            <li><strong>Motion Graphics & Animación:</strong> Damos vida a tus ideas con animaciones que capturan la atención y comunican tu mensaje de manera clara.</li></ul>`
                },
                {
                    id: "hostinger",
                    title: "Hostinger",
                    avatar: "https://upload.wikimedia.org/wikipedia/commons/0/03/Hostinger_Logo.png",
                    additionalLogo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Hostinger_logo_black.svg/2560px-Hostinger_logo_black.svg.png",
                    description: "Desde la creación de tu primer sitio web y el registro de tu dominio, hasta la configuración de un email profesional, la publicación de tu eCommerce o la migración de un sitio web existente. Aquí comienza tu éxito en Internet.",
                    deal: "20% OFF en Planes Cloud y VPS",
                    additionalImg: "https://d153chakadu90d.cloudfront.net/web/hostinger_partner.webp",
                    website: "https://hostinger.co?REFERRALCODE=FVZREBELRUON",
                    websiteLabel: "hostinger.com",
                    additionalText: ""
                    
                },
                {
                    id: "testimonial",
                    title: "Testimonial",
                    avatar: "https://testimonial.to/static/media/logo-dark.8447f219.svg",
                    additionalLogo: "https://testimonial.to/static/media/logo-dark.8447f219.svg",
                    description: "Obtén testimonios de tus clientes con facilidad | Recopilar testimonios es difícil, ¡lo entendemos! Así que creamos Testimonial. En minutos, puede recopilar testimonios de texto y video de sus clientes sin necesidad de un desarrollador o alojamiento de sitios web.",
                    deal: "15% OFF Primer Año",
                    additionalImg: "https://firebasestorage.googleapis.com/v0/b/testimonialto.appspot.com/o/assets%2Ffeatures%2Fmore-social-proof.png?alt=media&token=83a1a3e9-449d-457d-80fb-0cfa55484700",
                    website: "https://testimonial.to/?via=pill-ats",
                    websiteLabel: "testimonial.to",
                    additionalText: ""
                    
                },
                {
                    id: "growbox",
                    title: "GrowBox",
                    avatar: "https://media.licdn.com/dms/image/v2/D4E0BAQH2utbMWUTsLw/company-logo_200_200/company-logo_200_200/0/1728939051907/growboxdigital_logo?e=2147483647&v=beta&t=ACEntyX7p46XGr0FkIFlxBrggWC0tb1fOVMc_tUY3rM",
                    additionalLogo: "https://www.growboxdigital.com/hubfs/Logo%20Growbox%20web.png",
                    description: "Impulsa tu crecimiento con nuestra implementación y optimización experta. Como Agencia Partner de Hubspot, desbloqueamos todo el potencial de la plataforma para llevar tus estrategias de marketing y ventas al siguiente nivel.",
                    deal: "Special Offer",
                    additionalImg: "",
                    website: "https://www.growboxdigital.com/",
                    websiteLabel: "growboxdigital.com",
                    additionalText: ""
                }

            ],

        }
    },
    computed: {
        filteredBenefit() {
            // Obtener el id de la ruta
            const id = this.$route.params.id;
            // Filtrar el array según el id
            return this.cardsBenefitsPartner.find(benefit => benefit.id === id) || null;
        },
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register")
        },

        goToBenefits() {
            this.$router.push("/benefits")
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: black;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>