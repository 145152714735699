<template>
    <v-container class="mt-2">
        <center>
            <p class="mt-8">Powered and Supported by</p>
            <v-row class="mt-5">
                <v-col>
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/openai-grey-logo.webp" width="12rem"></v-img>
                </v-col>
                <v-col>
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/microsoft-grey-logo.webp" width="12rem"></v-img>
                </v-col>
                <v-col>
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/aws-grey-logo.webp" width="12rem"></v-img>
                </v-col>
            </v-row>
            <br>
            <br>
        </center>
        <center>
            <h2 class="hidden-sm-and-down title-style mt-7">¿Cansados de hojas de vida por correo? <br> <span style="font-weight: 400;">Moderniza tu
                reclutamiento</span> <span style="background-color: #7579E7; border-radius: 10px; color: white"><span
                        class="mx-2" style="font-weight: 500;">Gratis.</span></span></h2>
            <h2 class="hidden-md-and-up title-style-mb mt-3">¿Cansados de hojas de vida por correo? <br> <span style="font-weight: 400;">Moderniza tu
                reclutamiento</span> <span style="background-color: #7579E7; border-radius: 10px; color: white"><span
                        class="mx-2" style="font-weight: 500;">Gratis.</span></span></h2>
        </center>
        <v-card class="mx-auto mt-16 hidden-sm-and-down" elevation="3" outlined
            style="height: min-content; max-height: none; background-color: #1B1B1B; border-radius: 27px;">
            <v-row>
                <v-col style="max-width: 50%;" class="ml-16">
                    <v-img src="https://d153chakadu90d.cloudfront.net/web/vacantes_portal.webp" width="80%"
                        alt="Custom ChatGPT chat for business example 1"></v-img>
                </v-col>
                <v-col>
                    <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Crea y personaliza tu
                        <br>portal de empleos.</h2>
                    <p style="color: white; font-size: large;" class="mt-10">Agrega el <b>logo</b> de tu empresa,<br>
                        tus <b>colores</b> y más!</p>

                    <v-btn @click="goToRegister()" rounded class="mt-9 white--text" color="#7579E7">
                        Ver más <v-icon right color="white">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card class="mx-auto mx-1 mt-16 hidden-md-and-up black-card" elevation="3" outlined>
            <v-row>
                <v-img src="https://d153chakadu90d.cloudfront.net/web/vacantes_portal.webp"
                    alt="Custom ChatGPT Assistant chat for business"></v-img>
            </v-row>
            <v-row class="mt-7">
                <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Crea y personaliza tu
                    <br>portal de empleos.</h2>
                <p style="color: white;" class="ml-5 mt-3">Agrega el <b>logo</b> de tu empresa,<br> tus <b>colores</b> y
                    más!</p>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goToRegister()" rounded class="mt-5 ml-5 white--text" color="#7579E7">
                    Ver más<v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>


export default {
    name: 'MultipurposeView',
    components: {
    },
    methods: {
        goToRegister() {
            window.open('https://app.pill.com.co/register', "_self");
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: black;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: black;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}



.black-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: #1B1B1B;
}
</style>
